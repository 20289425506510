<template>
  <div class="wrap">
    <div class="tagWrap">
      <el-button @click="chageTag(0)">一</el-button>
      <el-button @click="chageTag(1)">二</el-button>
      <el-button @click="chageTag(2)">三</el-button>
    </div>

    <div class="consWrap">
      <div style="position: relative;z-index:0;width: 930px;height: 200px;">
        <div :style="{zIndex:zIndex1}">
          <div class="swiper-container swiper-container0">
            <div class="swiper-wrapper">
              <!-- 这里的数据需要使用自己定义的数组或者从后端返回的数组 -->
              <div class="swiper-slide swiper-no-swiping" v-for="item in game0" :key="item"><img :src="item" alt=""></div>
            </div>
          </div>
        </div>

        <div :style="{zIndex:zIndex2}">
          <div class="swiper-container swiper-container1">
            <div class="swiper-wrapper swiper-wrapper1">
              <!-- 这里的数据需要使用自己定义的数组或者从后端返回的数组 -->
              <div class="swiper-slide swiper-no-swiping swiper-slide1" v-for="item in game1" :key="item.id"><img :src="item" alt=""></div>
            </div>
          </div>
        </div>

        <div :style="{zIndex:zIndex3}">
          <div class="swiper-container swiper-container2">
            <div class="swiper-wrapper swiper-wrapper2">
              <!-- 这里的数据需要使用自己定义的数组或者从后端返回的数组 -->
              <div class="swiper-slide swiper-no-swiping swiper-slide2" v-for="item in game2" :key="item.id"><img :src="item" alt=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Swiper from "swiper";

  export default {
    name: 'leftTop',
    props: {
      msg: String
    },
    data() {
      return {
        zIndex1:3,
        zIndex2:2,
        zIndex3:1,
        game0:[
          require('@/assets/imgs/home/game0/0.png'),
          require('@/assets/imgs/home/game0/1.png'),
          require('@/assets/imgs/home/game0/2.png'),
          require('@/assets/imgs/home/game0/3.png'),
          require('@/assets/imgs/home/game0/4.png'),
          require('@/assets/imgs/home/game0/5.png'),
          require('@/assets/imgs/home/game0/6.png'),
          require('@/assets/imgs/home/game0/7.png'),
          require('@/assets/imgs/home/game0/8.png'),
          require('@/assets/imgs/home/game0/9.png'),
        ],
        game1:[
          require('@/assets/imgs/home/game1/0.png'),
          require('@/assets/imgs/home/game1/1.png'),
          require('@/assets/imgs/home/game1/2.png'),
          require('@/assets/imgs/home/game1/3.png'),
          require('@/assets/imgs/home/game1/4.png'),
          require('@/assets/imgs/home/game1/5.png'),
          require('@/assets/imgs/home/game1/6.png'),
          require('@/assets/imgs/home/game1/7.png'),
          require('@/assets/imgs/home/game1/8.png'),
          require('@/assets/imgs/home/game1/9.png'),
          require('@/assets/imgs/home/game1/10.png'),
          require('@/assets/imgs/home/game1/11.png'),
          require('@/assets/imgs/home/game1/12.png'),
          require('@/assets/imgs/home/game1/13.png'),
          require('@/assets/imgs/home/game1/14.png'),
          require('@/assets/imgs/home/game1/15.png'),
          require('@/assets/imgs/home/game1/16.png'),
          require('@/assets/imgs/home/game1/17.png'),
          require('@/assets/imgs/home/game1/18.png'),
          require('@/assets/imgs/home/game1/19.png'),
          require('@/assets/imgs/home/game1/20.png'),
        ],
        game2:[
          require('@/assets/imgs/home/game2/0.png'),
          require('@/assets/imgs/home/game2/1.png'),
          require('@/assets/imgs/home/game2/2.png'),
          require('@/assets/imgs/home/game2/3.png'),
          require('@/assets/imgs/home/game2/4.png'),
        ],
      }
    },
    mounted(){
      this.$nextTick(()=>{
        this.initSwiper()
        this.initSwiper1()
        this.initSwiper2()
      })
    },
    methods:{
      /* 精彩瞬间 */
      chageTag(data){
        if(data.ind == 0){
          this.zIndex1=4
          this.zIndex2=2
          this.zIndex3=3
        }
        else if(data.ind == 1){
          this.zIndex1=1
          this.zIndex2=4
          this.zIndex3=3
        }
        else if(data.ind == 2){
          this.zIndex1=1
          this.zIndex2=2
          this.zIndex3=4
        }
        /*this.initSwiper()
        this.initSwiper1()
        this.initSwiper2()*/
      },
      initSwiper(){
        new Swiper('.swiper-container0', {
          autoplay: {
            delay: 3000,//1秒切换一次
          },//等同于以下设置

          loop: true,
          slidesPerView: 4,//显示4个
          speed:3000,
          initialSlide:0,
        })
      },
      initSwiper1(){
        new Swiper('.swiper-container1', {
          autoplay: {
            delay: 3000,//1秒切换一次
          },//等同于以下设置
          delay: 8000,//1秒切换一次
          loop: true,
          slidesPerView: 4,//显示4个
          speed:3000,
          initialSlide:0,
        })
      },
      initSwiper2(){
        new Swiper('.swiper-container2', {
          autoplay: {
            delay: 3000,//1秒切换一次
          },//等同于以下设置
          delay: 8000,//1秒切换一次
          loop: true,
          slidesPerView: 4,//显示4个
          speed:3000,
          initialSlide:0,
        })
      },
    },
  }

</script>

<style scoped lang="less">

</style>
